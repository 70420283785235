import React from 'react';
import Loadable from "@loadable/component"
// Style Imports
import "./DoctorDiscussionGuide.scss"
// Content Imports
import Anchor from "@components/content/anchor.jsx"
const ConversationIcon = Loadable(() => import('@components/content/icons/Conversation.jsx'))

const DoctorDiscussionGuideCTA = () => {
	return (
		<section id="DoctorDiscussionGuide" className="bg-light-green text-center">
            <div className="container">
                <div className="icon">
                    <ConversationIcon />
                </div>
                <h2>Feel confident talking to your doctor about LUPKYNIS</h2>
                <p>Our downloadable doctor discussion guide can help make starting the conversation easier.</p>
                <div>
                    <Anchor url="/pdfs/doctor-discussion-guide.pdf" target="_blank" linkClass="button">Download</Anchor>
                </div>
            </div>
        </section>
    );
};

export default DoctorDiscussionGuideCTA;